@import url("./media.css");
body {
  margin: 0;
  padding: 0;
}
.banner_img {
  /* height: 1500px; */
  background-image: url("../assets/img/HERO-HEADER.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/* fonts  */
.font-dm {
  font-family: "DM Sans", sans-serif;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}
.video_img {
  background-image: url("../assets/img/video-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
